import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    experienceSectors: []
  },
  mutations: {
    SET_EXP_SECTORS (state, experienceSectors) {
      state.experienceSectors = experienceSectors
    },
    ADD_EXP_SECTOR (state, sector) {
      state.experienceSectors.push(sector)
    },
    UPDATE_EXP_SECTOR (state, sector) {
      const sectorIndex = state.experienceSectors.findIndex((s) => s.id === sector.id)
      state.experienceSectors[sectorIndex] = sector
    },
    REMOVE_EXP_SECTOR (state, sectorId) {
      const sectorIndex = state.experienceSectors.findIndex((s) => s.id === sectorId)
      state.experienceSectors.splice(sectorIndex, 1)
    }
  },
  actions: {
    addExperienceSector ({ commit }, sector) {
      return new Promise((resolve, reject) => {
        axios.post('/sector_experiences', sector)
          .then((response) => {
            commit('ADD_EXP_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchExperienceSectors ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/sector_experiences'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_EXP_SECTORS', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteExperienceSector ({ commit }, sectorId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/sector_experiences/${sectorId}`)
          .then((response) => {
            commit('REMOVE_EXP_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateExperienceSector ({ commit }, sector) {
      return new Promise((resolve, reject) => {
        axios.put(`/sector_experiences/${sector.id}`, sector)
          .then((response) => {
            commit('UPDATE_EXP_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    experienceSectors (state) {
      return state.experienceSectors.filter(experienceSectors => !experienceSectors.isOther)
    },
    otherExperienceSectors (state) {
      return state.experienceSectors.filter(experienceSectors => experienceSectors.isOther)
    },
    getOtherExperienceSectorsByName: (state, getters) => (name) => {
      return getters.experienceSectors.find(experienceSectors => experienceSectors.name.toLowerCase() === name.toLowerCase())
    }
  }
}
