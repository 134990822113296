import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    coreSkills: []
  },
  mutations: {
    SET_CORE_SKILLS (state, coreSkills) {
      state.coreSkills = coreSkills
    },
    ADD_CORE_SKILL (state, skill) {
      state.coreSkills.push(skill)
    },
    UPDATE_CORE_SKILL (state, skill) {
      const skillIndex = state.coreSkills.findIndex((s) => s.id === skill.id)
      state.coreSkills[skillIndex] = skill
    },
    REMOVE_CORE_SKILL (state, skillId) {
      const skillIndex = state.coreSkills.findIndex((s) => s.id === skillId)
      state.coreSkills.splice(skillIndex, 1)
    }
  },
  actions: {
    addCoreSkill ({ commit }, skill) {
      return new Promise((resolve, reject) => {
        axios.post('/core_skills', skill)
          .then((response) => {
            commit('ADD_CORE_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchCoreSkills ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/core_skills'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_CORE_SKILLS', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteCoreSkill ({ commit }, skillId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/core_skills/${skillId}`)
          .then((response) => {
            commit('REMOVE_CORE_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateCoreSkill ({ commit }, skill) {
      return new Promise((resolve, reject) => {
        axios.put(`/core_skills/${skill.id}`, skill)
          .then((response) => {
            commit('UPDATE_CORE_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    coreSkills (state) {
      return state.coreSkills.filter(coreSkill => !coreSkill.isSpecialisation)
    },
    specialisationCoreSkills (state) {
      return state.coreSkills.filter(coreSkill => coreSkill.isSpecialisation)
    },
    getSpecialisationCoreSkillsByName: (state, getters) => (name) => {
      return getters.specialisationCoreSkills.find(coreSkill => coreSkill.name.toLowerCase() === name.toLowerCase())
    }
  }
}
