import moduleHiringSector from './moduleHiringSector'

export default {
  created () {
    if (!moduleHiringSector.isRegistered) {
      this.$store.registerModule('hiring_sector', moduleHiringSector)
      moduleHiringSector.isRegistered = true
    }
  }
}
