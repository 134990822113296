import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    detailledSkills: []
  },
  mutations: {
    SET_DETAILLED_SKILLS (state, detailledSkills) {
      state.detailledSkills = detailledSkills
    },
    ADD_DETAILLED_SKILL (state, skill) {
      state.detailledSkills.push(skill)
    },
    UPDATE_DETAILLED_SKILL (state, skill) {
      const skillIndex = state.detailledSkills.findIndex((s) => s.id === skill.id)
      state.detailledSkills[skillIndex] = skill
    },
    REMOVE_DETAILLED_SKILL (state, skillId) {
      const skillIndex = state.detailledSkills.findIndex((s) => s.id === skillId)
      state.detailledSkills.splice(skillIndex, 1)
    }
  },
  actions: {
    addDetailledSkill ({ commit }, skill) {
      return new Promise((resolve, reject) => {
        axios.post('/detailled_skills', skill)
          .then((response) => {
            commit('ADD_DETAILLED_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchDetailledSkills ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/detailled_skills'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_DETAILLED_SKILLS', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteDetailledSkill ({ commit }, skillId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/detailled_skills/${skillId}`)
          .then((response) => {
            commit('REMOVE_DETAILLED_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateDetailledSkill ({ commit }, skill) {
      return new Promise((resolve, reject) => {
        axios.put(`/detailled_skills/${skill.id}`, skill)
          .then((response) => {
            commit('UPDATE_DETAILLED_SKILL', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    detailledSkills (state) {
      return state.detailledSkills.filter(detailledSkills => !detailledSkills.isOther)
    },
    otherDetailledSkills (state) {
      return state.detailledSkills.filter(detailledSkills => detailledSkills.isOther)
    },
    getOtherDetailledSkillsByName: (state, getters) => (name) => {
      return getters.otherDetailledSkills.find(detailledSkills => detailledSkills.name.toLowerCase() === name.toLowerCase())
    }
    // detailledSkillsPartners (state) {
    //   return state.detailledSkills.filter(detailledSkills => detailledSkills.partners)
    // }
  }
}
