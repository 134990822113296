import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    countries: []
  },
  mutations: {
    SET_COUNTRIES (state, countries) {
      state.countries = countries
    }
  },
  actions: {
    fetchCountries ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/countries?pagination=false'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_COUNTRIES', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    countriesList (state) {
      return state.countries
    },
    findCountryById: (state) => (id) => {
      return state.countries.find(country => country['@id'] === id)
    },
    countriesPartners (state) {
      return state.countries.filter(country => country.cities.length)
    }
  }
}
