import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    expertServices: []
  },
  mutations: {
    SET_EXPERT_SERVICES (state, expertServices) {
      state.expertServices = expertServices
    },
    ADD_EXPERT_SERVICE (state, service) {
      state.expertServices.push(service)
    },
    UPDATE_EXPERT_SERVICE (state, service) {
      const serviceIndex = state.expertServices.findIndex((s) => s.id === service.id)
      state.expertServices[serviceIndex] = service
    },
    REMOVE_EXPERT_SERVICE (state, serviceId) {
      const serviceIndex = state.expertServices.findIndex((s) => s.id === serviceId)
      state.expertServices.splice(serviceIndex, 1)
    }
  },
  actions: {
    addExpertService ({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios.post('/expert_service_portfolios', service)
          .then((response) => {
            commit('ADD_EXPERT_SERVICE', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchExpertServices ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/expert_service_portfolios'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_EXPERT_SERVICES', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteExpertService ({ commit }, serviceId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/expert_service_portfolios/${serviceId}`)
          .then((response) => {
            commit('REMOVE_EXPERT_SERVICE', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateExpertService ({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios.put(`/expert_service_portfolios/${service.id}`, service)
          .then((response) => {
            commit('UPDATE_EXPERT_SERVICE', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    expertServices (state) {
      return state.expertServices.filter(expertServices => !expertServices.isOther)
    },
    otherExpertServices (state) {
      return state.expertServices.filter(expertServices => expertServices.isOther)
    },
    getOtherExpertServicesByName: (state, getters) => (name) => {
      return getters.otherExpertServices.find(expertServices => expertServices.name.toLowerCase() === name.toLowerCase())
    }
  }
}
