import moduleDetailledSkill from './moduleDetailledSkill'

export default {
  created () {
    if (!moduleDetailledSkill.isRegistered) {
      this.$store.registerModule('detailled_skill', moduleDetailledSkill)
      moduleDetailledSkill.isRegistered = true
    }
  }
}
