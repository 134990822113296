import moduleExpertService from './moduleExpertService'

export default {
  created () {
    if (!moduleExpertService.isRegistered) {
      this.$store.registerModule('expert_service', moduleExpertService)
      moduleExpertService.isRegistered = true
    }
  }
}
