<template>
  <div id="partner-add">
    <form-wizard ref="partnerForm" @on-complete="savePartnerForm"
                 color="rgb(var(--vs-primary))"
                 :title="null"
                 :subtitle="null"
                 :nextButtonText="$t('partner.form.button.continue')"
                 :backButtonText="$t('partner.form.button.back')"
                 :finishButtonText="$t('partner.form.button.submit')"
                 class="w-full lg:w-4/5 mx-auto">
      <tab-content :before-change="validateStep1">
        <ValidationObserver v-slot="{ invalid }" ref="step1">
          <form>
            <div class="vx-row">
              <div class="vx-col w-full md:w-1/2">
                <fieldset class="form-fieldset block">
                  <legend>{{ $t('partner.form.fieldset.partner.name') }}</legend>

                  <ValidationProvider :name="$t('partner.form.input.partner.name')" rules="required" v-slot="{ errors }">
                    <vs-input class="w-full mt-4" :label="$t('partner.form.input.partner.name')" :placeholder="$t('partner.form.input.partner.name.placeholder')" v-model="partner.name" name="partnername" />
                    <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </fieldset>

                <fieldset class="form-fieldset" v-if="partner.locations.length && partner.locations[0].cities">
                  <div v-for="(location, idx) in partner.locations" :key="idx">
                    <!-- Country input field -->
                      <vx-input-group>
                        <template slot="append" v-if="partner.locations.length > 1">
                          <div class="append-text btn-addon">
                            <vs-button color="primary" icon="remove" class="mt-6" @click="removeCountry(partner, idx, true)"></vs-button>
                          </div>
                        </template>
                        <ValidationProvider :name="$t('partner.form.input.partner.location.country')" rules="required" v-slot="{ errors }">
                          <form autocomplete="off">
                            <vs-select class="w-full" :label="$t('partner.form.input.partner.location.country')" :placeholder="$t('partner.form.input.partner.location.country.placeholder')" v-model="location.country" name="partnerlocation_country" :autocomplete="true" >
                              <vs-select-item :key="item['@id']" :value="item['@id']" :text="item.name" v-for="item in countriesList" />
                            </vs-select>
                          </form>
                          <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </vx-input-group>

                    <!-- City input field -->
                    <div v-for="(city, idxCity) in location.cities" :key="idxCity">
                      <ValidationProvider class="mt-6" v-if="location.cities" :name="$t('partner.form.input.partner.location.city')" rules="required" v-slot="{ errors }">
                        <vx-input-group class="mt-6 ml-8">
                          <template slot="append" v-if="location.cities.length > 1">
                            <div class="append-text btn-addon">
                              <vs-button color="primary" icon="remove" @click="removeCity(location, idxCity)"></vs-button>
                            </div>
                          </template>
                          <vue-simple-suggest v-if="location.country" class="w-full"
                                              :label="idxCity <= 0 ?$t('partner.form.input.partner.location.city'):''"
                                              :placeholder="$t('partner.form.input.partner.location.city.placeholder')"
                                              v-model="location.cities[idxCity]"
                                              name="partnerlocation_city"
                                              :list="cityList(location.country)"
                                              display-attribute="name"
                                              value-attribute="@id"
                                              :filter-by-query="true"
                          />
                        </vx-input-group>
                        <div v-if="errors.length > 0 || invalid" class="ml-8 con-text-validation span-text-validation span-text-validation-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <!-- Add City button -->
                    <div v-if="location.cities && location.cities[location.cities.length - 1]" class="ml-8">
                      <vs-button
                        class="p-1 mb-5" type="flat" text-color="white" size="small"
                        @click="addCity(location)"
                      >{{ $t('partner.form.button.addCity') }}</vs-button>
                    </div>
                    <!-- /Add City button -->

                    <!-- /City input field -->
                    <!-- /Country input field -->
                  </div>
                  <!-- Add Country button -->
                  <div v-if="partner.locations[partner.locations.length -1].country">
                    <vs-button
                      class="p-0 p-1" type="flat" text-color="white" size="small"
                      @click="addCountry(partner.locations, true)"
                    >{{ $t('partner.form.button.addCountry') }}</vs-button>
                  </div>
                  <!-- /Add Country button -->

                </fieldset>

                <fieldset class="form-fieldset">
                  <legend class="mb-4">{{ $t('partner.form.fieldset.markets_worked') }}</legend>

                  <div v-for="(market, idx) in partner.marketsCountry" :key="idx">
                    <ValidationProvider :name="$t('partner.form.input.partner.markets_worked.country')" rules="required" v-slot="{ errors }">
                      <vx-input-group>
                        <template slot="append" v-if="partner.marketsCountry.length > 1">
                          <div class="append-text btn-addon">
                            <vs-button color="primary" icon="remove" :class="idx <= 0 ? 'mt-6':'mt-2'" @click="removeCountry(partner, idx, false)"></vs-button>
                          </div>
                        </template>
                        <form autocomplete="off">
                          <vs-select :class="idx <= 0 ?'w-full' : 'w-full mt-2'"
                                     :label="idx <= 0 ? $t('partner.form.input.partner.markets_worked.country'): ''"
                                     :placeholder="$t('partner.form.input.partner.markets_worked.country.placeholder')"
                                     v-model="partner.marketsCountry[idx]"
                                     name="marketsworked_country" :autocomplete="true"
                          >
                            <vs-select-item :key="item.id" :value="item['@id']" :text="item.name" v-for="item in countriesList" />
                          </vs-select>
                        </form>
                      </vx-input-group>
                      <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- Add Country button -->
                  <div v-if="partner.marketsCountry.length && partner.marketsCountry[partner.marketsCountry.length - 1]">
                    <vs-button
                      class="p-0 p-1" type="flat" text-color="white" size="small"
                      @click="addCountry(partner.marketsCountry, false)"
                    >{{ $t('partner.form.button.addMarket') }}</vs-button>
                  </div>
                  <!-- /Add Country button -->
                </fieldset>

                <fieldset class="form-fieldset">
                  <legend>
                    {{ $t('partner.form.fieldset.presentation') }}
                  </legend>
                  <ValidationProvider :name="$t('partner.form.fieldset.video_link')"
                                      rules="url"
                                      v-slot="{ errors }">
                    <vs-input icon-after
                              icon-no-border
                              icon-pack="feather"
                              icon="icon-youtube"
                              class="w-full mt-4"
                              :label="$t('partner.form.fieldset.video_link')"
                              :placeholder="$t('partner.form.input.video.link.placeholder')"
                              v-model="partner.videoUrl"
                              name="partnervideo_url"
                    />
                    <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider :name="$t('partner.form.fieldset.deck_link')"
                                      rules="url"
                                      v-slot="{ errors }">
                    <vs-input icon-after
                              icon-no-border
                              icon-pack="feather"
                              icon="icon-file"
                              class="w-full mt-4"
                              :label="$t('partner.form.fieldset.deck_link')"
                              :placeholder="$t('partner.form.fieldset.deck_link.placeholder')"
                              v-model="partner.deckUrl"
                              name="partnerdeck_url"
                      />
                    <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider :name="$t('partner.form.fieldset.agency_link')"
                                      rules="url"
                                      v-slot="{ errors }">
                    <vs-input icon-after
                              icon-no-border
                              icon-pack="feather"
                              icon="icon-link"
                              class="w-full mt-4"
                              :label="$t('partner.form.fieldset.agency_link')"
                              :placeholder="$t('partner.form.fieldset.agency_link.placeholder')"
                              v-model="partner.agencyPageUrl"
                              name="partneragency_page_url"
                    />
                    <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </fieldset>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </tab-content>
      <tab-content :before-change="validateStep2">
        <ValidationObserver v-slot="{ invalid }" ref="step2">
          <form>
            <div class="vx-row">
              <div class="vx-col w-full lg:w-1/2">
                <fieldset class="form-fieldset">
                  <legend class="mb-4">{{ $t('partner.form.fieldset.keycontact') }}</legend>

                  <div v-for="(contact, idx) in partner.keyContacts" :key="idx" :class="idx > 0 ? 'mt-8' : ''">
                    <vx-card :ref="`contact-${idx}`"
                             :subtitle="contact.firstname || contact.lastname ? `${contact.firstname} ${contact.lastname}` : ''"
                             :collapse-action="true"
                             :remove-card-action="true"
                             @remove="removeKeyContact(partner, idx)"
                    >

                      <ValidationProvider :name="$t('partner.form.input.keycontact.firstname')" rules="required" v-slot="{ errors }">
                        <vs-input class="w-full" :label="$t('partner.form.input.keycontact.firstname')" :placeholder="$t('partner.form.input.keycontact.firstname.placeholder')" v-model="contact.firstname" name="keycontact[][firstname]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider :name="$t('partner.form.input.keycontact.lastname')" rules="required" v-slot="{ errors }">
                        <vs-input class="w-full mt-4" :label="$t('partner.form.input.keycontact.lastname')" :placeholder="$t('partner.form.input.keycontact.lastname.placeholder')" v-model="contact.lastname" name="keycontact[][lastname]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider :name="$t('partner.form.input.keycontact.role')" rules="required" v-slot="{ errors }">
                        <vs-input class="w-full mt-4" :label="$t('partner.form.input.keycontact.role')" :placeholder="$t('partner.form.input.keycontact.role.placeholder')" v-model="contact.role" name="keycontact[][role]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider :name="$t('partner.form.input.keycontact.phone')" rules="required" v-slot="{ errors }">
                        <vs-input icon-after icon-no-border icon="call" class="w-full mt-4" :label="$t('partner.form.input.keycontact.phone')" :placeholder="$t('partner.form.input.keycontact.phone.placeholder')" v-model="contact.phone" name="keycontact[][phone]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider :name="$t('partner.form.input.keycontact.email')" rules="required|email" v-slot="{ errors }">
                        <vs-input icon-after icon-no-border icon-pack="feather" icon="icon-mail" class="w-full mt-4" :label="$t('partner.form.input.keycontact.email')" :placeholder="$t('partner.form.input.keycontact.email.placeholder')" v-model="contact.email" name="keycontact[][email]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider :name="$t('partner.form.input.keycontact.linkedin')" :rules="{ regex: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/ }" v-slot="{ errors }">
                        <vs-input icon-after icon-no-border icon-pack="feather" icon="icon-linkedin" class="w-full mt-4" :label="$t('partner.form.input.keycontact.linkedin')" :placeholder="$t('partner.form.input.keycontact.linkedin.placeholder')" v-model="contact.linkedin" name="keycontact[][linkedin]"/>
                        <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </vx-card>
                  </div>

                  <!-- Add Key contact button -->
                  <div v-if="(partner.keyContacts.length && partner.keyContacts[partner.keyContacts.length - 1].firstname) || partner.keyContacts.length === 0">
                    <vs-button
                      class="mt-8 p-0 p-1" type="flat" text-color="white" size="small"
                      @click="addKeyContact(partner)"
                    >{{ $t('partner.form.button.addKeyContact') }}</vs-button>
                  </div>
                  <!-- /Add Key contact button -->

                </fieldset>

              </div>
            </div>
          </form>
        </ValidationObserver>
      </tab-content>
      <tab-content :before-change="validateStep3">
        <ValidationObserver v-slot="{ invalid }" ref="step3">
          <form>
            <fieldset class="form-fieldset">
              <legend class="text-lg underline mb-5">{{ $t('partner.form.fieldset.core_skills') }}</legend>

              <vs-card class="w-full bg-secondary text-black">
                <div class="flex items-center">
                  <div class="px-3 mr-2">
                    <vs-icon icon="info" color="primary" size="medium"></vs-icon>
                  </div>
                  <div class="w-3/4 py-2 text-lg flex-auto">
                    {{ $t('partner.form.disclaimer.core_skill.content') }}
                  </div>
                </div>
              </vs-card>
              <ValidationProvider :name="$t('partner.form.input.core_skill')" rules="required" v-slot="{ errors }">
                <ul class="leftx" id="coreSkillsList">
                  <li :key="item.id" v-for="item in coreSkills">
                    <vs-radio v-model="partner.coreSkill" :vs-value="item['@id']" @change="clearSpecialistInput()">{{ item.name }}</vs-radio>
                  </li>
                  <li>
                    <vs-radio v-model="partner.coreSkill" vs-value="specialist">{{ $t('partner.form.input.core_skill.specialist') }}</vs-radio>
                  </li>
                </ul>
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider class="block w-full md:w-1/2" v-if="partner.coreSkill === 'specialist'" :name="$t('partner.form.input.core_skill.specialist')" rules="required|max:35" v-slot="{ errors }">
                <vue-simple-suggest class="w-full mt-4"
                                    :label="$t('partner.form.input.core_skill.specialist')"
                                    :placeholder="$t('partner.form.input.core_skill.specialist.placeholder')"
                                    v-model="partner.core_skill_specialist"
                                    name="core_skill_specialist"
                                    :list="specialisationCoreSkills"
                                    display-attribute="name"
                                    value-attribute="id"
                                    :filter-by-query="true"
                />
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
            </fieldset>

             <vs-card class="w-full mt-10 bg-secondary text-black">
                <div class="flex items-center">
                  <div class="px-3 mr-2">
                    <vs-icon icon="info" color="primary" size="medium"></vs-icon>
                  </div>
                  <div class="w-3/4 py-2 text-lg flex-auto">
                    {{ $t('partner.form.disclaimer.skill_sets.content') }}
                  </div>
                </div>
              </vs-card>

            <fieldset class="form-fieldset">
              <legend class="text-lg underline">{{ $t('partner.form.fieldset.detailled_skills') }}</legend>
              <p class="definition mt-3 mb-5 antialiased" v-html="$t('partner.form.definition.detailled_skills')"></p>

              <ValidationProvider :name="$t('partner.form.input.detailled_skill')" rules="required" v-slot="{ errors }">
                <ul class="vx-row" id="detailledSkillsList">
                  <li class="vx-col block w-full lg:w-1/2" :key="item.id" v-for="item in detailledSkills">
                    <vs-checkbox v-model="partner.detailledSkills" :vs-value="item['@id']">{{ item.name }}</vs-checkbox>
                  </li>
                  <li class="vx-col block w-full lg:w-1/2">
                    <vs-checkbox v-model="partner.detailledSkills" vs-value="other" @change="clearOtherInput(partner, 'detailledSkills', 'detailled_skills_other')">{{ $t('partner.form.input.detailled_skills.other') }}</vs-checkbox>
                  </li>
                </ul>
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider class="block w-full md:w-1/2" v-if="partner.detailledSkills.includes('other')" :name="$t('partner.form.input.detailled_skills.other')" rules="required|max:35" v-slot="{ errors }">
                <vue-simple-suggest class="w-full mt-4"
                                    :label="$t('partner.form.input.detailled_skills.other')"
                                    :placeholder="$t('partner.form.input.detailled_skills.other.placeholder')"
                                    v-model="partner.detailled_skills_other"
                                    name="detailled_skills_other"
                                    :list="otherDetailledSkills"
                                    display-attribute="name"
                                    value-attribute="id"
                                    :filter-by-query="true"
                />
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
            </fieldset>

            <fieldset class="form-fieldset">
              <legend class="text-lg underline">{{ $t('partner.form.fieldset.expert_services') }}</legend>
              <p class="definition mt-3 mb-5 antialiased" v-html="$t('partner.form.definition.expert_services')"></p>

              <ValidationProvider :name="$t('partner.form.input.expert_services')" rules="required" v-slot="{ errors }">
                <ul class="vx-row" id="expertServicesList">
                  <li class="vx-col block w-full lg:w-1/2" :key="item.id" v-for="item in expertServices">
                    <vs-checkbox v-model="partner.expertServicePortfolios" :vs-value="item['@id']">{{ item.name }}</vs-checkbox>
                  </li>
                  <li class="vx-col block w-full lg:w-1/2">
                    <vs-checkbox v-model="partner.expertServicePortfolios" vs-value="other" @change="clearOtherInput(partner, 'expertServicePortfolios', 'expert_services_other')">{{ $t('partner.form.input.expert_services.other') }}</vs-checkbox>
                  </li>
                </ul>
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider class="block w-full" v-if="partner.expertServicePortfolios.includes('other')" :name="$t('partner.form.input.expert_services.other')" rules="required|max:35" v-slot="{ errors }">
                <vue-simple-suggest class="w-full mt-4"
                                    :label="$t('partner.form.input.expert_services.other')"
                                    :placeholder="$t('partner.form.input.expert_services.other.placeholder')"
                                    v-model="partner.expert_services_other"
                                    name="expert_services_other"
                                    :list="otherExpertServices"
                                    display-attribute="name"
                                    value-attribute="id"
                                    :filter-by-query="true"
                />
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
            </fieldset>

            <fieldset class="form-fieldset">
              <legend class="text-lg underline">{{ $t('partner.form.fieldset.sectors_exp') }}</legend>
              <p class="definition mt-3 mb-5 antialiased" v-html="$t('partner.form.definition.sectors_exp')"></p>

              <ValidationProvider :name="$t('partner.form.fieldset.sectors_exp')" rules="required" v-slot="{ errors }">
                <ul class="vx-row" id="sectorsExpList">
                  <li class="vx-col block md:w-1/2 w-full" :key="item.id" v-for="item in experienceSectors">
                    <vs-checkbox v-model="partner.sectorExperiences" :vs-value="item['@id']">{{ item.name }}</vs-checkbox>
                  </li>
                  <li class="vx-col block md:w-1/2 w-full">
                    <vs-checkbox v-model="partner.sectorExperiences" vs-value="other" @change="clearOtherInput(partner, 'sectorExperiences', 'sectors_exp_other')">{{ $t('partner.form.input.sectors_exp.other') }}</vs-checkbox>
                  </li>
                </ul>
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider class="block w-full" v-if="partner.sectorExperiences.includes('other')" :name="$t('partner.form.input.sectors_exp.other')" rules="required|max:35" v-slot="{ errors }">
                <vue-simple-suggest class="w-full mt-4"
                                    :label="$t('partner.form.input.sectors_exp.other')"
                                    :placeholder="$t('partner.form.input.sectors_exp.other.placeholder')"
                                    v-model="partner.sectors_exp_other"
                                    name="sectors_exp_other"
                                    :list="otherExperienceSectors"
                                    display-attribute="name"
                                    value-attribute="id"
                                    :filter-by-query="true"
                />
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
            </fieldset>

            <fieldset class="form-fieldset">
              <legend class="text-lg underline">{{ $t('partner.form.fieldset.hiring_sectors') }}</legend>
              <p class="definition mt-3 mb-5 antialiased" v-html="$t('partner.form.definition.hiring_sectors')"></p>

              <ValidationProvider :name="$t('partner.form.fieldset.hiring_sectors')" rules="" v-slot="{ errors }">
                <ul class="vx-row" id="hiringSectorsList">
                  <li class="vx-col block md:w-1/2 w-full" :key="item.id" v-for="item in hiringSectors">
                    <vs-checkbox v-model="partner.hiringSectors" :vs-value="item['@id']">{{ item.name }}</vs-checkbox>
                  </li>
                  <li class="vx-col block md:w-1/2 w-full">
                    <vs-checkbox v-model="partner.hiringSectors" vs-value="other" @change="clearOtherInput(partner, 'hiringSectors', 'hiring_sectors_other')">{{ $t('partner.form.input.hiring_sectors.other') }}</vs-checkbox>
                  </li>
                </ul>
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="block w-full" v-if="partner.hiringSectors.includes('other')" :name="$t('partner.form.input.hiring_sectors.other')" rules="max:35" v-slot="{ errors }">
                <vue-simple-suggest class="w-full mt-4"
                                    :label="$t('partner.form.input.hiring_sectors.other')"
                                    :placeholder="$t('partner.form.input.hiring_sectors.other.placeholder')"
                                    v-model="partner.hiring_sectors_other"
                                    name="hiring_sectors_other"
                                    :list="otherHiringSectors"
                                    display-attribute="name"
                                    value-attribute="id"
                                    :filter-by-query="true"
                />
                <span v-if="errors.length > 0 || invalid" class="con-text-validation span-text-validation-danger span-text-validation">{{ errors[0] }}</span>
              </ValidationProvider>
            </fieldset>
          </form>
        </ValidationObserver>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>

const cloneDeep = require('lodash.clonedeep')

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

import modulePartnerMixin from '@/store/partner/modulePartnerMixin'
import moduleCoreSkillMixin from '@/store/partner/moduleCoreSkillMixin'
import moduleDetailledSkillMixin from '@/store/partner/moduleDetailledSkillMixin'
import moduleExpertServiceMixin from '@/store/partner/moduleExpertServiceMixin'
import moduleExperienceSectorMixin from '@/store/partner/moduleExperienceSectorMixin'
import moduleHiringSectorMixin from '@/store/partner/moduleHiringSectorMixin'
import moduleCountryMixin from '@/store/country/moduleCountryMixin'
import moduleCityMixin from '@/store/partner/moduleCityMixin'
import moduleKeyContactMixin from '@/store/partner/moduleKeyContactMixin'

import { mapGetters,  mapActions} from 'vuex'

export default {
  components: {
    FormWizard,
    TabContent,
    VueSimpleSuggest
  },
  metaInfo: {
    title: 'My company'
  },
  mixins: [
    modulePartnerMixin,
    moduleCoreSkillMixin,
    moduleDetailledSkillMixin,
    moduleExpertServiceMixin,
    moduleExperienceSectorMixin,
    moduleHiringSectorMixin,
    moduleCityMixin,
    moduleKeyContactMixin,
    moduleCountryMixin
  ],
  data () {
    return {
      isModified: false,
      partner: {}
    }
  },
  computed: {
    ...mapGetters({
      countriesList: 'country/countriesList',
      coreSkills: 'core_skill/coreSkills',
      specialisationCoreSkills: 'core_skill/specialisationCoreSkills',
      detailledSkills: 'detailled_skill/detailledSkills',
      otherDetailledSkills: 'detailled_skill/otherDetailledSkills',
      expertServices: 'expert_service/expertServices',
      otherExpertServices: 'expert_service/otherExpertServices',
      experienceSectors: 'experience_sector/experienceSectors',
      otherExperienceSectors: 'experience_sector/otherExperienceSectors',
      hiringSectors: 'hiring_sector/hiringSectors',
      otherHiringSectors: 'hiring_sector/otherHiringSectors',
      cityList: 'city/getCitiesByCountrySimpleSuggest'
    })
  },
  methods: {
    validateStep1 () {
      return this.$refs.step1.validate().then(success => {
        return success
      })
    },
    validateStep2 () {
      return this.$refs.step2.validate().then(success => {
        return success
      })
    },
    validateStep3 () {
      return this.$refs.step3.validate().then(success => {
        if (success) {
          this.savePartnerForm()
        }
      })
    },
    addCity (obj) {
      const city = null
      this.$nextTick(function () {
        return obj.cities.push(city)
      })
    },
    removeCity (obj, index) {
      this.$nextTick(function () {
        return obj.cities.splice(index, 1)
      })
    },
    addCountry (obj, canHaveCities) {
      const country = canHaveCities ? { country: null, cities: [null] } : null
      this.$nextTick(function () {
        return obj.push(country)
      })
    },
    removeCountry (obj, index, canHaveCities) {
      canHaveCities = typeof canHaveCities !== undefined ? canHaveCities : false
      this.$nextTick(function () {
        return canHaveCities ? obj.locations.splice(index, 1) : obj.marketsCountry.splice(index, 1)
      })
    },
    addKeyContact (obj) {
      const contact = {
        firstname: '',
        lastname: '',
        role: '',
        phone: '',
        email: '',
        linkedin: ''
      }
      this.$nextTick(function () {
        return obj.keyContacts.push(contact)
      })
    },
    removeKeyContact (obj, index) {
      this.$nextTick(function () {
        return obj.keyContacts.splice(index, 1)
      })
    },
    clearSpecialistInput ()  {
      if (this.partner.core_skill_specialist) delete this.partner.core_skill_specialist
    },
    clearOtherInput (partner, listName, inputName) {
      if (!partner[listName].includes('other')) {
        delete partner[inputName]
      }
    },
    async savePartnerForm () {
      await this.beforeUpdate()
      if (this.partner.id) {
        await this.$store.dispatch('partner/updatePartner', this.partner)
          .then(() => {
            this.isModified = false
            this.$router.push({'name': 'home'})
            this.$vs.notify({
              color: 'success',
              title: this.$t('partner.form.update.success.notify.title'),
              text: this.$t('partner.form.update.success.notify.text', { partner: this.partner.name })
            })
          })
          .catch((error) => {
            console.error(error)// eslint-disable-line no-console
            this.$vs.notify({
              color: 'danger',
              title: this.$t('partner.form.update.error.notify.title'),
              text: this.$t('partner.form.update.error.notify.text', { error: error.response.data['hydra:description'] }),
              fixed: true
            })
          })
      } else {
        await this.$store.dispatch('partner/newPartner', this.partner)
          .then(() => {
            this.isModified = false
            this.$router.push({'name': 'home'})
            this.$vs.notify({
              color: 'success',
              title: this.$t('partner.form.submit.success.notify.title'),
              text: this.$t('partner.form.submit.success.notify.text', { partner: this.partner.name })
            })
          })
          .catch((error) => {
            console.error(error)// eslint-disable-line no-console
            this.$vs.notify({
              color: 'danger',
              title: this.$t('partner.form.submit.error.notify.title'),
              text: this.$t('partner.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
              fixed: true
            })
          })
      }
    },
    async beforeUpdate () {
      this.isModified = true
      await this.addLocation()
      await this.addContact()
      await this.addCoreSkillSpecialisation()
      await this.addDetailledSkillOther()
      await this.addExpertServiceOther()
      await this.addExperienceSectorOther()
      await this.addHiringSectorOther()
    },
    async addLocation () {
      if (!this.partner.locations) {
        return
      }

      const partnerLocation = []
      for (const [, location] of Object.entries(this.partner.locations)) {
        const countryId = location.country
        const country = this.$store.getters['country/findCountryById'](countryId)

        if (!country) {
          return
        }

        await location.cities.forEach((cityLocale) => {
          if (!cityLocale) {
            return
          }

          let city = this.$store.getters['city/getCityByCountryAndName'](country, cityLocale)

          if (city) {
            partnerLocation.push(city['@id'])
            return
          }

          city = {
            name: cityLocale,
            country: country['@id']
          }

          this.$store.dispatch('city/addCity', city)
            .then((response) => {
              partnerLocation.push(response.data['@id'])
            })
            .catch((error) => {
              console.error(error) // eslint-disable-line no-console
            })
        })
      }
      this.partner.locations = partnerLocation
    },
    async addContact () {
      if (!this.partner.keyContacts) {
        return
      }
      const partnerKeyContacts = []
      for (const [, keyContact] of Object.entries(this.partner.keyContacts)) {
        if (keyContact.id) {
          await this.$store.dispatch('key_contact/replaceKeyContact', keyContact)
            .then((response) => {
              partnerKeyContacts.push(response.data['@id'])
            })
        } else {
          await this.$store.dispatch('key_contact/addKeyContact', keyContact)
            .then((response) => {
              partnerKeyContacts.push(response.data['@id'])
            })
        }
      }
      this.partner.keyContacts = partnerKeyContacts
    },
    addCoreSkillSpecialisation () {
      if (!this.partner.core_skill_specialist) {
        return
      }

      const found = this.$store.getters['core_skill/getSpecialisationCoreSkillsByName'](this.partner.core_skill_specialist)

      if (found) {
        this.setCoreSkillSpecialisation(found)
        return
      }

      const skill = {
        name: this.partner.core_skill_specialist,
        isSpecialisation: true
      }

      return this.$store.dispatch('core_skill/addCoreSkill', skill)
        .then((response) => {
          this.setCoreSkillSpecialisation(response.data)
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    setCoreSkillSpecialisation (skill) {
      this.partner.coreSkill = skill['@id']
    },
    addDetailledSkillOther () {
      if (!this.partner.detailled_skills_other) {
        return
      }

      const found = this.$store.getters['detailled_skill/getOtherDetailledSkillsByName'](this.partner.detailled_skills_other)

      if (found) {
        this.setDetailledSkillOther(found)
        return
      }

      const skill = {
        name: this.partner.detailled_skills_other,
        isOther: true
      }

      return this.$store.dispatch('detailled_skill/addDetailledSkill', skill)
        .then((response) => {
          this.setDetailledSkillOther(response.data)
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    setDetailledSkillOther (skill) {
      this.partner.detailledSkills = this.partner.detailledSkills.filter((skill) => {
        return skill !== 'other'
      })
      this.partner.detailledSkills.push(skill['@id'])
    },
    addExpertServiceOther () {
      if (!this.partner.expert_services_other) {
        return
      }

      const found = this.$store.getters['expert_service/getOtherExpertServicesByName'](this.partner.expert_services_other)

      if (found) {
        this.setExpertServiceOther(found)
        return
      }

      const service = {
        name: this.partner.expert_services_other,
        isOther: true
      }

      return this.$store.dispatch('expert_service/addExpertService', service)
        .then((response) => {
          this.setExpertServiceOther(response.data)
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    setExpertServiceOther  (service) {
      this.partner.expertServicePortfolios = this.partner.expertServicePortfolios.filter((service) => {
        return service !== 'other'
      })
      this.partner.expertServicePortfolios.push(service['@id'])
    },
    addExperienceSectorOther () {
      if (!this.partner.sectors_exp_other) {
        return
      }

      const found = this.$store.getters['experience_sector/getOtherExperienceSectorsByName'](this.partner.sectors_exp_other)

      if (found) {
        this.setExperienceSectorOther(found)
        return
      }

      const sector = {
        name: this.partner.sectors_exp_other,
        isOther: true
      }

      return this.$store.dispatch('experience_sector/addExperienceSector', sector)
        .then((response) => {
          this.setExperienceSectorOther(response.data)
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    setExperienceSectorOther  (service) {
      this.partner.sectorExperiences = this.partner.sectorExperiences.filter((service) => {
        return service !== 'other'
      })
      this.partner.sectorExperiences.push(service['@id'])
    },
    addHiringSectorOther () {
      if (!this.partner.hiring_sectors_other) {
        return
      }

      const found = this.$store.getters['hiring_sector/getOtherHiringSectorsByName'](this.partner.hiring_sectors_other)

      if (found) {
        this.setHiringSectorOther(found)
        return
      }

      const sector = {
        name: this.partner.hiring_sectors_other,
        isOther: true
      }

      return this.$store.dispatch('hiring_sector/addHiringSector', sector)
        .then((response) => {
          this.setHiringSectorOther(response.data)
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    setHiringSectorOther (sector) {
      this.partner.hiringSectors = this.partner.hiringSectors.filter((sector) => {
        return sector !== 'other'
      })
      this.partner.hiringSectors.push(sector['@id'])
    },
    ...mapActions(
      [
        'country/fetchCountries',
        'core_skill/fetchCoreSkills',
        'detailled_skill/fetchDetailledSkills',
        'expert_service/fetchExpertServices',
        'experience_sector/fetchExperienceSectors',
        'hiring_sector/fetchHiringSectors',
        'city/fetchCities',
        'partner/fetchPartnerByUserId'
      ])
  },
  created () {
    this.partner = cloneDeep(this.$store.state.partner.partner)
    this.$vs.loading({
      background: 'primary'
    })
    this['partner/fetchPartnerByUserId'](this.$store.state.AppActiveUser.id)
      .then(() => {
        this.partner = cloneDeep(this.$store.state.partner.partner)

        // Create schema => locations: [{ country: null, cities: [''] }],
        let locations = this.partner.locations.map(location => {
          const obj = {
            country: location.country['@id'],
            cities: [location.name]
          }
          return obj
        })
        locations = Object.values(locations.reduce((prev, curr) => {
          const { country, cities = [] } = curr
          const [city] = cities
          if (prev[country] && city) {
            prev[country].cities.push(city)
          } else {
            prev[country] = { country, cities: city ? [city] : [] }
          }
          return prev
        }, {}))
        this.partner.locations = locations

        // Create schema => marketsCountry: [{ country: null }],
        const marketsCountry = this.partner.marketsCountry.map(market => {
          const obj = market['@id']
          return obj
        })
        this.partner.marketsCountry = marketsCountry

        // Create schema => coreSkill: null
        if (!this.partner.coreSkill.isSpecialisation) {
          this.partner.coreSkill = this.partner.coreSkill['@id']
        } else {
          this.partner.core_skill_specialist = this.partner.coreSkill.name
          this.partner.coreSkill = 'specialist'
        }

        const displayUpdateIsOther = (partner, listName, inputName) => {
          const newStructure = partner[listName].map(element => {
            const arr = !element.isOther ? element['@id'] : 'other'
            if (arr.includes('other')) {
              partner[inputName] = element.name
            }
            return arr
          })
          partner[listName] = newStructure
        }

        displayUpdateIsOther(this.partner, 'detailledSkills', 'detailled_skills_other')
        displayUpdateIsOther(this.partner, 'expertServicePortfolios', 'expert_services_other')
        displayUpdateIsOther(this.partner, 'sectorExperiences', 'sectors_exp_other')
        displayUpdateIsOther(this.partner, 'hiringSectors', 'hiring_sectors_other')
      })
      .then(() => {
        this.$vs.loading.close()
      })
      .catch((error) => {
        this.$vs.loading.close()
        console.error(error) // eslint-disable-line no-console
      })

    this['city/fetchCities']({pagination: false})
    this['country/fetchCountries']({pagination: false})
    this['core_skill/fetchCoreSkills']({pagination: false})
    this['detailled_skill/fetchDetailledSkills']({pagination: false})
    this['expert_service/fetchExpertServices']({pagination: false})
    this['experience_sector/fetchExperienceSectors']({pagination: false})
    this['hiring_sector/fetchHiringSectors']({pagination: false})
  }
}
</script>

<style>

</style>
