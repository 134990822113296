import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    keyContacts: []
  },
  mutations: {
    ADD_CONTACT (state, contact) {
      state.keyContacts.push(contact)
    }
  },
  actions: {
    addKeyContact ({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios.post('/contacts', contact)
          .then((response) => {
            commit('ADD_CONTACT', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    replaceKeyContact ({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios.put(`/contacts/${contact.id}`, contact)
          .then((response) => {
            commit('ADD_CONTACT', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {

  }
}
