import moduleKeyContact from './moduleKeyContact'

export default {
  created () {
    if (!moduleKeyContact.isRegistered) {
      this.$store.registerModule('key_contact', moduleKeyContact)
      moduleKeyContact.isRegistered = true
    }
  }
}
