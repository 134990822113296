import moduleCountries from './moduleCountry'

export default {
  created () {
    if (!moduleCountries.isRegistered) {
      this.$store.registerModule('country', moduleCountries)
      moduleCountries.isRegistered = true
    }
  }
}
