import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    hiringSectors: []
  },
  mutations: {
    SET_HIRING_SECTORS (state, sectorsData) {
      state.hiringSectors = sectorsData['hydra:member']
    },
    ADD_HIRING_SECTOR (state, sector) {
      state.hiringSectors.push(sector)
    },
    UPDATE_HIRING_SECTOR (state, sector) {
      const sectorIndex = state.hiringSectors.findIndex((s) => s.id === sector.id)
      state.hiringSectors[sectorIndex] = sector
    },
    REMOVE_HIRING_SECTOR (state, sectorId) {
      const sectorIndex = state.hiringSectors.findIndex((s) => s.id === sectorId)
      state.hiringSectors.splice(sectorIndex, 1)
    }
  },
  actions: {
    addHiringSector ({ commit }, sector) {
      return new Promise((resolve, reject) => {
        axios.post('/hiring_sectors', sector)
          .then((response) => {
            commit('ADD_HIRING_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchHiringSectors ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/hiring_sectors'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_HIRING_SECTORS', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteHiringSector ({ commit }, sectorId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/hiring_sectors/${sectorId}`)
          .then((response) => {
            commit('REMOVE_HIRING_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateHiringSector ({ commit }, sector) {
      return new Promise((resolve, reject) => {
        axios.put(`/hiring_sectors/${sector.id}`, sector)
          .then((response) => {
            commit('UPDATE_HIRING_SECTOR', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    hiringSectors (state) {
      return state.hiringSectors.filter(hiringSectors => !hiringSectors.isOther)
    },
    otherHiringSectors (state) {
      return state.hiringSectors.filter(hiringSectors => hiringSectors.isOther)
    },
    getOtherHiringSectorsByName: (state, getters) => (name) => {
      return getters.otherHiringSectors.find(hiringSectors => hiringSectors.name.toLowerCase() === name.toLowerCase())
    }
  }
}
