import moduleCoreSkill from './moduleCoreSkill'

export default {
  created () {
    if (!moduleCoreSkill.isRegistered) {
      this.$store.registerModule('core_skill', moduleCoreSkill)
      moduleCoreSkill.isRegistered = true
    }
  }
}
