import moduleExperienceSector from './moduleExperienceSector'

export default {
  created () {
    if (!moduleExperienceSector.isRegistered) {
      this.$store.registerModule('experience_sector', moduleExperienceSector)
      moduleExperienceSector.isRegistered = true
    }
  }
}
