import moduleCity from './moduleCity'

export default {
  created () {
    if (!moduleCity.isRegistered) {
      this.$store.registerModule('city', moduleCity)
      moduleCity.isRegistered = true
    }
  }
}
