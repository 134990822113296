import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    cities: []
  },
  mutations: {
    SET_CITIES (state, cities) {
      state.cities = cities
    },
    ADD_CITY (state, city) {
      state.cities.push(city)
    }
  },
  actions: {
    addCity ({ commit }, city) {
      return new Promise((resolve, reject) => {
        axios.post('/cities', city)
          .then((response) => {
            commit('ADD_CITY', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchCities ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = '/cities'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url)
          .then((response) => {
            commit('SET_CITIES', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    getCitiesByCountrySimpleSuggest: (state) => (country) => {
      return state.cities.filter(city => city.country === country)
    },
    getCitiesByCountry: (state) => (country) => {
      return state.cities.filter(city => city.country === country['@id'])
    },
    getCityByCountryAndName: (state, getters) => (country, name) => {
      return getters.getCitiesByCountry(country).find(city => city.name.toLowerCase() === name.toLowerCase())
    }
  }
}
